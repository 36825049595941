/*
Use : Use for check permission available for perticular functionaliy
Author : sufiyan Patel
Date:28-04-2024
*/
import moment from "moment";
import {
  decryptDataForURL,
  encryptData,
  encryptDataForURL,
} from "../utills/useCryptoUtils";
import { use } from "i18next";
import { store } from "./store";
import { GetTenantDetails } from "./commonServices";
export const rolesDetails = [
  { role: "BU", label: "Operation Executive" },
  { role: "LR", label: "Lab Receptionist" },
  { role: "TM", label: "Technical Manager" },
  { role: "DTM", label: "Deputy Technical Manager" },
  { role: "LC", label: "Chemist" },
  { role: "OPS ADMIN", label: "Admin" },
  { role: "STM", label: "Senior Technical Manager" },
  { role: "QM", label: "Quality Manager" },
  { role: "SQM", label: "Senior Technical Manager" },
  { role: "SLC", label: "Senior Chemist" },
];
export const CommonTMRoles = ["TM", "STM", "QM", "SQM", "SLC"];
/*
Use : Use for check permission available for module and showing in sidebar
Author : sufiyan Patel
Date:28-04-2024
*/

export const formatDate = (dateString) => {
  if (!dateString) return "";
  const [datePart] = dateString.split("T");
  const [year, month, day] = datePart.split("-");
  return `${day}/${month}/${year}`;
};

export const isModuelePermission = (
  allPermissions,
  module,
  permission,
  isMainPrmission,
  iscustom = ""
) => {
  if (iscustom) {
    return true;
  }
  const state = store.getState();
  const userData = state.session.user;
  if (isMainPrmission) {
    if (module === "operations") {
      if (["BU", "OPS ADMIN", "BH"].includes(userData?.role)) {
        return true;
      } else {
        return false;
      }
    }
    if (module === "LMS") {
      if (!["OPS ADMIN", "BH"].includes(userData?.role)) {
        return true;
      } else {
        return false;
      }
    }
    return true;
  }
  // return true
  if (iscustom) {
    return true;
  }
  if (GetTenantDetails(1, 1) === "TPBPL" && module === "sampleverification") {
    return false;
  }
  module = permission + "_" + module;
  if (allPermissions) {
    let allPermissionsArrray = allPermissions;
    let isValideData = allPermissionsArrray.filter((per, i) => {
      var spPer = per.split(".");
      let spValue = "";
      if (spPer.length > 1) {
        spValue = spPer[1];
      } else {
        spValue = spPer[0];
      }
      return spValue === module;
    });
    if (isValideData.length > 0) {
      return true;
    }
  }

  return false;
};

/*
Use : Use for Check Validation of password field
Author : sufiyan Patel
Date:08-04-2024
*/
export const checkPasswordValidation = (password = "") => {
  const passAuth = new RegExp(
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,15}$/
  );
  return password.match(passAuth) !== null ? true : false;
};
/*
Use : use for get proper date format
Author : sufiyan Patel
Date:08-04-2024
*/
export const getFormatedDate = (
  date,
  isListing,
  idDownLoad = "",
  isShowColorifPassed
) => {
  if (!date) {
    return "--";
  }
  let actualDate = new Date(date);
  let formattedDate = "";
  if (isListing || idDownLoad) {
    formattedDate = moment(actualDate).format("DD/MM/YYYY");
  } else {
    formattedDate = moment(actualDate).format("MMMM DD,YY kk:mm");
  }
  if (isShowColorifPassed) {
    const currentDate = new Date();
    if (currentDate > actualDate) {
      return (<span style={{ color: 'red' }}>{formattedDate}</span>);
    }
  }
  return formattedDate;
};
export const getFormatedDateWithtime = (date, isListing) => {
  if (!date) {
    return "--";
  }
  let actualDate = new Date(date);
  if (isListing) {
    return moment(actualDate).format("DD/MM/YYYY H:mm:ss");
  } else {
    return moment(actualDate).format("MMMM DD,YY kk:mm");
  }
};
export const verifyPassword = (password) => {
  const result = [];
  result.push(password.length >= 8 && password.length <= 15);
  result.push(/[A-Z]/.test(password));
  result.push(/[a-z]/.test(password));
  result.push(/\d/.test(password));
  result.push(/[@$!%*?&]/.test(password));
  return result;
};

export const getPasswordVerificationHint = (password) => {
  const passwordVerificationResult = verifyPassword(password);
  return (
    <ul className="passwordPlolicyhint">
      <p style={{ color: "green", fontSize: "0.875rem", lineHeight: 1 }}>
        {passwordVerificationResult.map((verification, index) => (
          <li
            className="errorMsg"
            key={"index" + index}
            style={{ color: verification ? "green" : "$danger" }}
          >
            {verification ? "✓" : "✗"}&nbsp;
            {index === 0
              ? "Password should be minimum 8 and maximum 15 characters"
              : ""}
            {index === 1 ? "At least one uppercase letter" : ""}
            {index === 2 ? "One lowercase letter" : ""}
            {index === 3 ? "One number" : ""}
            {index === 4 ? "One special character" : ""}
          </li>
        ))}
      </p>
    </ul>
  );
  return "";
};
export const redirectPageAfterLogin = (navigate, role, isReload) => {
  const jrfRoles = ["LR"];
  const allotRoles = ["TM", "STM", "QM", "SQM", "SLC", "DTM"];
  const verificationRoles = ["LC", "SLC"];
  const operations = ["OPS ADMIN", "BU"];
  if (role !== undefined) {
    if (jrfRoles.includes(role)) {
      navigate("/jrfListing");
    } else if (allotRoles.includes(role)) {
      navigate("/testmemoList");
    } else if (verificationRoles.includes(role)) {
      navigate("/allotmentList");
    } else if (operations.includes(role)) {
      navigate("/operation/jrfInstructionListing");
    } else if (role === "BH") {
      navigate("/operation/commercial-certificate-list");
    } else {
      navigate("/jrfListing");
    }
    if (isReload) {
      setTimeout(() => {
        window.location.reload();
      }, 10);
    }
  }
};

export const getSelectedOptionName = (
  options = [],
  masterOptions = [],
  name = "",
  value,
  customname = "",
  isSingleValue,
  isArray,
  isCustomOption,
  customOptions
) => {
  let allOptions = [];
  let newOptions = [];
  let selectedOptions = [];
  if (isSingleValue) {
    if (typeof value === "string") {
      selectedOptions.push(value);
    } else {
      selectedOptions.push(parseInt(value));
    }
  } else {
    selectedOptions = value;
  }
  if (!selectedOptions || !selectedOptions.length) {
    return [];
  }
  if (isCustomOption) {
    newOptions = customOptions;
  } else {
    masterOptions?.map((model, index) => {
      if (model.model === name || model.model === customname) {
        newOptions = model.data;
      }
    });
  }

  if (newOptions.length > 0) {
    newOptions.forEach((option) => {
      if (
        selectedOptions.includes(option.id) ||
        selectedOptions.includes(option.name) ||
        selectedOptions.includes(option.id.toString())
      ) {
        allOptions.push(option.name);
      }
    });
  } else {
    allOptions = options.filter((option) => {
      if (selectedOptions.includes(option)) {
        return option;
      }
      return false;
    });
  }

  if (isArray) {
    if (allOptions.length > 0) {
      return allOptions;
    } else {
      return selectedOptions;
    }
  }
  if (allOptions.length > 0) {
    return allOptions.join(", ");
  } else {
    return selectedOptions.join(", ");
  }
};
export const getComonCodeForCompany = (code) => {
  const companyCodes = {
    L: "TIPL",
    C: "TCRC",
    P: "TPBPL",
  };
  return companyCodes[code];
};
export const getTotalValues = (formData, type, header) => {
  let count = 0;
  header.map((field) => {
    if (formData[1][field.name + "-" + type]) {
      count =
        parseFloat(count) + parseFloat(formData[1][field.name + "-" + type]);
    }
  });
  return count;
};
export const getDisplacementDifferenceCalc = (formData) => {
  let value1 = formData[1]?.["corrected_displacement-initial"] || 0;
  let value2 = formData[1]?.["corrected_displacement-interim"] || 0;
  let value3 = formData[1]?.["corrected_displacement-final"] || 0;
  let finalValue =
    parseFloat(value3) - (parseFloat(value1) + parseFloat(value2));
  return finalValue;
};
export const getChangeOnShipsValue = (formData, initialTotal, finalTotal) => {
  let finalValue = 0;
  if (formData[0].ji_is_loading === "Loading") {
    finalValue = parseFloat(initialTotal) - parseFloat(finalTotal);
  } else {
    finalValue = parseFloat(finalTotal) - parseFloat(initialTotal);
  }
  return finalValue;
};

export const shortArray = (array, fieldName, type = "asc") => {
  let sortArr = [];
  if (type === "asc") {
    sortArr = array.sort((a, b) => a[fieldName] - b[fieldName]);
  } else {
    sortArr = array.sort((a, b) => b[fieldName] - a[fieldName]);
  }
  return sortArr;
};

export const getDayCountFromDate = (fromDate, toDate, isColor = "") => {
  let diffDays = 0;
  if (fromDate || toDate) {
    const endDate = toDate ? moment(toDate) : moment();
    const startDate = fromDate ? moment(fromDate) : moment();
    if (endDate.isSame(startDate, "date")) {
    }
    diffDays = endDate.diff(startDate, "days");
    diffDays = diffDays + 1;
  }
  diffDays = diffDays > 0 ? diffDays : 0;
  return isColor ? getDaysColorCount(diffDays) : diffDays;
};
const getDaysColorCount = (count) => {
  let color = "#e11d07";
  if (count >= 5 && count <= 10) {
    color = "#ffbc34";
  } else if (count > 10) {
    color = "#53ff34";
  }
  return (
    <span style={{ color: color }}>
      {count + (count > 1 ? " Days" : " Day")}
    </span>
  );
};

// export const getCellData = (cellData) => {
//   if (!cellData) {
//     return "--";
//   }
//   const breakingLimit = 18
//   if (cellData.length > breakingLimit) {
//     const firstLine = cellData.substring(0, breakingLimit); // Get the first 20 characters
//     let secondLine
//     if (cellData.length < 2 * breakingLimit) {
//       secondLine = cellData.substring(breakingLimit, breakingLimit * 2); // Get the rest of the characters
//     }
//     else {
//       secondLine = cellData.substring(breakingLimit, breakingLimit * 2 - 3) + "..."; // Get the rest of the characters
//     }
//     return <span>{firstLine}<br />{secondLine}</span>; // Return as two lines
//   }

//   return cellData;
// };

export const getCellData = (cellData) => {
  if (!cellData) {
    return "--";
  }

  const breakingLimit = 20; // Maximum character limit per line
  if (cellData.length > breakingLimit) {
    // Find the last space within the first breakingLimit characters to avoid cutting words
    let firstLine = cellData.substring(0, breakingLimit);
    const lastSpaceIndex = firstLine.lastIndexOf(" ");

    // Adjust firstLine to end at the last space, if a space exists
    if (lastSpaceIndex !== -1) {
      firstLine = cellData.substring(0, lastSpaceIndex);
    }

    // The remaining text starts after the firstLine
    let secondLine = cellData.substring(firstLine.length).trim();

    // If the second line is too long, trim it and add ellipsis
    if (secondLine.length > breakingLimit) {
      secondLine = secondLine.substring(0, breakingLimit - 3).trim() + "...";
    }

    return (
      <span>
        {firstLine}
        <br />
        {secondLine}
      </span>
    );
  }

  return cellData;
};

export const numberToOrdinalWord = (num) => {
  const ordinals = [
    "Zeroth",
    "First",
    "Second",
    "Third",
    "Fourth",
    "Fifth",
    "Sixth",
    "Seventh",
    "Eighth",
    "Ninth",
    "Tenth",
    "Eleventh",
    "Twelfth",
    "Thirteenth",
    "Fourteenth",
    "Fifteenth",
    "Sixteenth",
    "Seventeenth",
    "Eighteenth",
    "Nineteenth",
    "Twentieth",
  ];
  if (num >= 0 && num <= 20) {
    return ordinals[num];
  } else {
    // For numbers > 20, we use a suffix approach.
    let suffix = ["th", "st", "nd", "rd"];
    let value = num % 100;
    let suffixWord = suffix[(value - 20) % 10] || suffix[value] || suffix[0];
    return num + suffixWord;
  }
};
export const getSvgAccordingToCondition = (action) => {
  if (action.text.toLowerCase() === "save" || action.icon === "bi bi-floppy2") {
    return (
      <svg
        className="svg-hover-effect"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.1667 17.5V10.8333H5.83333V17.5M5.83333 2.5V6.66667H12.5M15.8333 17.5H4.16667C3.72464 17.5 3.30072 17.3244 2.98816 17.0118C2.67559 16.6993 2.5 16.2754 2.5 15.8333V4.16667C2.5 3.72464 2.67559 3.30072 2.98816 2.98816C3.30072 2.67559 3.72464 2.5 4.16667 2.5H13.3333L17.5 6.66667V15.8333C17.5 16.2754 17.3244 16.6993 17.0118 17.0118C16.6993 17.3244 16.2754 17.5 15.8333 17.5Z"
          // stroke="#151B25" /* Initial color */
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }

  if (action.text.toLowerCase() === "edit" || action.icon === "bi bi-pen") {
    // return <img src={EditSVG} alt="Edit" />;
    return (
      <svg
        className="svg-hover-effect"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M4.536 18C4.1136 18 3.75213 17.8435 3.45158 17.5304C3.15053 17.2168 3 16.84 3 16.4V3.6C3 3.16 3.15053 2.7832 3.45158 2.4696C3.75213 2.15653 4.1136 2 4.536 2H10.68L15.288 6.8V10H13.752V7.6H9.912V3.6H4.536V16.4H9.144V18H4.536ZM13.9824 12.02L14.808 12.88L11.832 15.96V16.8H12.6384L15.6144 13.72L16.4208 14.56L13.1184 18H10.68V15.46L13.9824 12.02ZM16.4208 14.56L13.9824 12.02L15.096 10.86C15.2368 10.7133 15.416 10.64 15.6336 10.64C15.8512 10.64 16.0304 10.7133 16.1712 10.86L17.5344 12.28C17.6752 12.4267 17.7456 12.6133 17.7456 12.84C17.7456 13.0667 17.6752 13.2533 17.5344 13.4L16.4208 14.56Z"
          // fill="#151B25"
          strokeWidth="0"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="svg-hover-effect-path"

        />
      </svg>
    )
  }
  if (action.text.toLowerCase() === "delete" || action.icon === "bi bi-trash") {
    // return <img src={DeleteSVG} alt="Delete" />;
    return (

      <svg
        className="svg-hover-effect"
        width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.725 14L10 11.6889L12.275 14L13.5 12.7556L11.225 10.4444L13.5 8.13333L12.275 6.88889L10 9.2L7.725 6.88889L6.5 8.13333L8.775 10.4444L6.5 12.7556L7.725 14ZM5.625 18C5.14375 18 4.73192 17.8261 4.3895 17.4782C4.0465 17.1298 3.875 16.7111 3.875 16.2222V4.66667H3V2.88889H7.375V2H12.625V2.88889H17V4.66667H16.125V16.2222C16.125 16.7111 15.9538 17.1298 15.6114 17.4782C15.2684 17.8261 14.8562 18 14.375 18H5.625ZM14.375 4.66667H5.625V16.2222H14.375V4.66667Z"
          // fill="#151B25" 
          strokeWidth="0"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="svg-hover-effect-path"

        />
      </svg>
    )
  }
  return <i className={action.icon} title={action.text}></i>;
};
export const gettwoFieldsTotalValues = (firstName, secondName, currentName, value, formData, setFormData, setFieldName, sectionIndex) => {
  let totalValue = 0;
  if (firstName === currentName) {
    totalValue = formData?.[sectionIndex]?.[secondName] * value;
  }
  else {
    totalValue = formData?.[sectionIndex]?.[firstName] * value;
  }
  console.log('totalValuetotalValue', totalValue, setFieldName, sectionIndex)
  totalValue = totalValue ? totalValue : 0
  setFormData((prevFormData) => {
    return {
      ...prevFormData,
      [sectionIndex]: {
        ...prevFormData[sectionIndex],
        [setFieldName]: totalValue
      },
    };
  });
  return totalValue
}
export const getTotalCountForTable = (tableData, fieldName, sectionIndex, formatData) => {
  let total = 0;
  tableData.map((singletable, i) => {
    total += formatData?.[sectionIndex]?.[fieldName + '_' + i] ? parseFloat(formatData?.[sectionIndex]?.[fieldName + '_' + i]) : 0
  })
  total += formatData?.[sectionIndex]?.[fieldName + '_' + tableData.length] ? parseFloat(formatData?.[sectionIndex]?.[fieldName + '_' + tableData.length]) : 0

  return total;
}
export const imageToDataURL = async (imageUrl) => {
  try {
    const response = await fetch("https://cors-anywhere.herokuapp.com/" + imageUrl, { mode: 'cors' }); // Ensure the server supports CORS
    const blob = await response.blob();
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result); // Data URL
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  } catch (error) {
    console.error('Error fetching image:', error);
    return null;
  }
}
export const getLMSOperationActivity = () => {
  return [
    "Pre-Shipment (PSI)",
    "Quality Analysis",
    "Transportable moisture limit (TML)",
    getRakeOperations('QA')
  ]
}
export const getLMSOperationTMLPSI = () => {
  return [
    "Pre-Shipment (PSI)",
    "Transportable moisture limit (TML)",
  ]
}

export const getLMSOperationTML = () => {
  return "Transportable moisture limit (TML)"
}

export const getRakeOperations = (type = "") => {
  if (type == "QAss") {
    return "Quantity Assessment"
  }
  else {
    return "Quality Analysis (Rake)"
  }
}

export const getOperationActivityUrl = (operationMode) => {
  if (operationMode === "RAKE") {
    return "/operation/rake-list/rake-details-list/"
  }
  else {
    return "/operation/vessel-ji-list/vessel-list/"
  }
}

export const getVesselOperation = (type = "") => {
  if (type === "TML") {
    return "Transportable moisture limit (TML)"
  }
  else if (type === "PSI") {
    return "Pre-Shipment (PSI)"
  }
  else if (type === "QA") {
    return "Quality Analysis"
  }
  else if (type === "DS") {
    return "Draft Survey"
  }
  else if (type === "SV") {
    return "Supervision"
  }
  else if (type === "HH") {
    return "H&H"
  }
  else if (type === "TMLPSI") {
    return [
      "Pre-Shipment (PSI)",
      "Transportable moisture limit (TML)",
    ]
  }
  else {
    return [
      "Pre-Shipment (PSI)",
      "Quality Analysis",
      "Transportable moisture limit (TML)"
    ]
  }

}